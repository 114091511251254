import React, { Fragment, memo } from 'react';

function toNum(value) {
	if(!isNaN(value)) {
		return +value;
	}
	return value;
}

function ColliInput({ set, p, type, ...props }) {
	return <input
		{...props}
		type="number"
		min={0}
		onChange={set(p.i, type)}
		value={p.colli[type] || ''} />;
}

function ColliForm({ pallet, className, byPallet, setColli, setPallet }) {
	function set(index, key) {
		return e => setColli(c => {
			const copy = c.slice();
			copy[index] = Object.assign({}, c[index], { [key]: toNum(e.target.value) });
			return copy;
		});
	}
	return <>
		<dt className="col-sm-4">{pallet.name}</dt>
		<dd className="col-sm-8">
			<input
				className={className}
				type="number"
				min={0}
				onChange={e => setPallet(pallet.id, toNum(e.target.value || 0))}
				value={byPallet[pallet.id] ? byPallet[pallet.id].length : ''} />
		</dd>
		{byPallet[pallet.id] && pallet.type !== 'DIMENSIONLESS' && <>
			{pallet.type === 'PALLET' && <>
				<dt className="col-sm-6 font-weight-normal">Height (cm)</dt>
				<dd className="col-sm-6">Weight (kg)</dd>
			</>}
			{byPallet[pallet.id].map(p => {
				return <Fragment key={p.i}>
					{pallet.type === 'BOX' ? <>
						<dt className="col-sm-8 font-weight-normal">Doos {p.i + 1}</dt>
						<dd className="col-sm-4"></dd>
						<dt className="col-sm-8">
							<div className="row">
								<div className="col-sm-4">
									<ColliInput className={className} set={set} p={p} type="length" placeholder="Length (cm)" />
								</div>
								<div className="col-sm-4">
									<ColliInput className={className} set={set} p={p} type="width" placeholder="Width (cm)" />
								</div>
								<div className="col-sm-4">
									<ColliInput className={className} set={set} p={p} type="height" placeholder="Height (cm)" />
								</div>
							</div>
						</dt>
					</> : <dt className="col-sm-6">
						<ColliInput className={className} set={set} p={p} type="height" placeholder="Height (cm)" />
					</dt>}
					<dd className={`col-sm-${pallet.type === 'BOX' ? '4' : '6'}`}>
						<ColliInput className={className} set={set} p={p} type="weight" placeholder="Weight (kg)" />
					</dd>
				</Fragment>;
			})}
		</>}
	</>;
}

function OrderPallets({ pallets, readonly, abc, colli, setPallet, setColli, dropship, byPallet }) {
	const maxPallets = abc.reduce((all, { maxPallets: max }) => Math.max(all, max), 0);
	const className = maxPallets && colli.length > maxPallets ? 'form-control is-invalid' : 'form-control';
	return <div>
		{dropship ? <h3>Colli</h3> : <h3>
			Pallets
			{maxPallets > 0 && <small className="text-danger"> (Max. {maxPallets})</small>}
		</h3>}
		<dl className="row">
			{!readonly && pallets.map(pallet => <ColliForm key={pallet.id} pallet={pallet} className={className} byPallet={byPallet} setColli={setColli} setPallet={setPallet} />)}
			{readonly && pallets.filter(pallet => byPallet[pallet.id]).map(pallet => <Fragment key={pallet.id}>
				<dt className="col-sm-4">{pallet.name}</dt>
				<dd className="col-sm-8">{byPallet[pallet.id].length}</dd>
			</Fragment>)}
		</dl>
	</div>;
}

export default memo(OrderPallets);
