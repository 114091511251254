import axios from 'axios';
import HttpStatus from 'http-status-codes';
import dispatch from './batch';
import { discardLocalChanges, hasLocalChanges } from './websocket';
const IMG_API = process.env.REACT_APP_IMG_API;
const UPGRADE_REQUIRED = 426;

export const version = 64;

export const MAX_MOVING = 10;

const api = axios.create({
	baseURL: '/api',
	headers: {
		'PTMD-loods-version': version
	}
});

export function getLoginUrl(role) {
	return `${window.location.origin}/api/login?redirect=${encodeURIComponent(window.location)}&role=${encodeURIComponent(role)}`;
}

export function getImage(code, size = 'big') {
	return `${IMG_API}/${size}/${code}.jpg`;
}

export function reset() {
	discardLocalChanges();
	dispatch({ type: 'setPerson', person: null });
	dispatch({ type: 'setOrders', orders: [] });
	dispatch({ type: 'setStock', stock: [] });
}

function checkLogout(err) {
	if(err.response && [HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(err.response.status)) {
		reset();
	}
	return Promise.reject(err);
}

function checkVersion(err) {
	if(err.response && err.response.status === UPGRADE_REQUIRED && !hasLocalChanges()) {
		window.location.reload();
	}
	return Promise.reject(err);
}

function get(url, params) {
	return api.get(url, { params }).catch(checkVersion).catch(checkLogout);
}

function post(url, data) {
	return api.post(url, data).catch(checkVersion).catch(checkLogout);
}

function del(url) {
	return api.delete(url).catch(checkVersion).catch(checkLogout);
}

export function logout() {
	return post('/logout').then(reset);
}

export function checkLoggedIn() {
	return get('/persons/me').then(({ data: person }) => {
		dispatch({ type: 'setPerson', person });
		return true;
	}).catch(err => {
		if(err.response && err.response.data) {
			return err.response.data.login;
		}
	});
}

export function getOrders(cantSet = () => false) {
	return get('/orders').then(({ data: orders }) => {
		if(!cantSet()) {
			dispatch({ type: 'setOrders', orders });
		}
	}).catch(err => {
		console.error('Failed to get orders', err);
	});
}

export function getPersons() {
	return get('/persons').then(({ data: persons }) => {
		persons.sort((lhs, rhs) => {
			if(lhs.name > rhs.name) {
				return 1;
			} else if(lhs.name < rhs.name) {
				return -1;
			}
			return 0;
		});
		dispatch({ type: 'setPersons', persons });
		return true;
	}).catch(err => {
		console.error('Failed to get persons', err);
		return false;
	});
}

export function getLocations() {
	return get('/locations').then(({ data: locations }) => {
		dispatch({ type: 'setLocations', locations });
	}).catch(err => {
		console.error('Failed to get locations', err);
	});
}

export function getFreight() {
	return get('/locations/freight').then(({ data: freight }) => {
		dispatch({ type: 'setFreight', freight });
	}).catch(err => {
		console.error('Failed to get freight locations', err);
	});
}

export function addOrder(code, date, dropship = false) {
	return post('/management', { code, date, dropship });
}

export function getPallets() {
	return get('/pallets').then(({ data: pallets }) => {
		dispatch({ type: 'setPallets', pallets });
	}).catch(err => {
		console.error('Failed to get pallets', err);
	});
}

export function getTerms() {
	return get('/management/edi/terms').then(({ data: terms }) => {
		dispatch({ type: 'setTerms', terms });
	}).catch(err => {
		console.error('Failed to get terms', err);
	});
}

export function getCod() {
	return get('/management/edi/cod').then(({ data: cod }) => {
		dispatch({ type: 'setCOD', cod });
	}).catch(err => {
		console.error('Failed to get cod', err);
	});
}

export function getDelivery() {
	return get('/management/edi/delivery').then(({ data: delivery }) => {
		dispatch({ type: 'setDelivery', delivery });
	}).catch(err => {
		console.error('Failed to get delivery', err);
	});
}

export function createEdi(orders) {
	return post('/management/edi', orders).then(({ data }) => data);
}

export function mailEdi(orders) {
	return post('/management/edi/mail', orders);
}

export function getStickers(input) {
	return post('/management/edi/stickers', input).then(({ data }) => data);
}

export function invoice(orders) {
	return post('/management/invoice', orders).then(({ data }) => data);
}

export function getStock() {
	return get('/stock').then(({ data: stock }) => {
		dispatch({ type: 'setStock', stock });
	}).catch(err => {
		console.error('Failed to get stock', err);
	});
}

export function getContainer(container) {
	return get(`/stock/container?id=${encodeURIComponent(container)}`).then(({ data }) => data);
}

export function exportStock() {
	window.open('/api/stock/export');
}

export function exportOnPallet() {
	window.open('/api/management/onPallet');
}

export function moveMultiple(mutation) {
	return post('/stock/move/multiple', mutation);
}

export function getMovingItems() {
	return get('/stock/move/items').then(res => res.data);
}

export function getContainers() {
	return get('/stock/containers').then(res => res.data);
}

export function findOrder(id) {
	return get(`/orders/${encodeURIComponent(id)}`).then(({ data }) => {
		dispatch({ type: 'findOrder', orders: data });
		return data;
	});
}

export function exportFailed(date) {
	window.open(`/api/management/failed/1/${date}`);
}

export function exportLocationMutations(date) {
	window.open(`/api/management/mutations/${date}`);
}

export function exportMutations(date, type) {
	window.open(`/api/management/mutations/${type}/1/${date}`);
}

export function exportProductivity(date) {
	window.open(`/api/management/productivity/${date.slice(0, 'YYYY-MM'.length)}`);
}

export function exportInvoiced(period, date = new Date()) {
	window.open(`/api/management/invoiced/${period}/${date.toISOString().slice(0, 'YYYY-MM-DD'.length)}`);
}

export function exportCreated(period, date = new Date()) {
	window.open(`/api/management/created/${period}/${date.toISOString().slice(0, 'YYYY-MM-DD'.length)}`);
}

export function exportDeleted(period, date = new Date()) {
	window.open(`/api/management/deleted/${period}/${date.toISOString().slice(0, 'YYYY-MM-DD'.length)}`);
}

export function exportOrders(date) {
	window.open(`/api/management/orders/1/${date}`);
}

export function printStickers(order) {
	return post(`/orders/${encodeURIComponent(order)}/stickers`);
}

export function breakItems(lines) {
	return post('/stock/break', { lines });
}

export function deleteMovingItem(item, person) {
	return post('/stock/updateMoving', {
		item, person, delete: true
	});
}

export function setMovingItemPerson(item, person, newPerson) {
	return post('/stock/updateMoving', {
		item, person, newPerson
	});
}

export function getAddress(customer) {
	return get(`/addresses/${encodeURIComponent(customer)}`).then(({ data }) => data);
}

export function returnItems(lines) {
	return post('/stock/return', { lines });
}

export function getInterestingItems() {
	return get('/items/interesting').then(res => res.data);
}

export function addInterestingItem(item) {
	return post('/items/interesting', { item });
}

export function removeInterestingItem(item) {
	return del(`/items/interesting/${encodeURIComponent(item)}`);
}

export function updateAddresses(orders) {
	return post('/management/orderAddress', orders);
}
